import '../styles/index.scss';
import 'bootstrap';
import $ from 'jquery';
import 'slick-carousel';
import AOS from 'aos';
import Headroom from 'headroom.js/dist/headroom.js';
import enquire from 'enquire.js';

$(document).ready(function(){
  // Ajax Email
  $("form.cta-block").on('submit', function(event){
    event.preventDefault();

    var form = $(this);
    var email = $(this).find('input[type=email]').val()
    var button = $(this).find('.clap-btn')
    button.attr('disabled', true)
    button.html('Chargement...')
    form.removeClass('error')

    $.post("https://api.clap.video/api/users/registered-emails", {"email": email}, function(){
      form.addClass('success')
    })
    .fail(function(err){
      var error = err.responseJSON.email[0];
      form.addClass('error')
      form.find('.error-message').html(error)
    })
    .always(function(){
      button.attr('disabled', false)
      button.html('Démarrer')
    })

    return false;
  })


    // Ref Cookies
    let searchParams = new URLSearchParams(window.location.search);

    let ref = searchParams.get('ref');
    if(ref){
        document.cookie = "ref="+ref + ";";
    }

    let base_url, ref_url;

    if(getCookie("ref")){
        $(".add-ref").each((i, el) => {
            base_url = $(el).attr('href');
            ref_url = base_url + "?ref=" + getCookie("ref");

            $(el).attr('href', ref_url);
        });
    }

  // // Hide header on scroll
  // // grab an element
  // const header = document.querySelector("#menu");
  // const headroom  = new Headroom(header, {
  //     tolerance: 5,
  //     offset : 205,
  //     classes: {
  //       initial: "animated",
  //       pinned: "slideDown",
  //       unpinned: "slideUp"
  //     }
  // });
  //
  // // Active menu on page load
  // if ($(window).scrollTop() > 0) {
  //   $("header").addClass("active");
  // }
  // if ($(window).scrollTop() == 0) {
  //   $("header").removeClass("active");
  // }
  //
  // // Active menu on scroll
  // $(window).scroll(function() {
  //   if ($(window).scrollTop() > 0) {
  //     $("header").addClass("active");
  //   }
  //   if ($(window).scrollTop() == 0) {
  //     $("header").removeClass("active");
  //   }
  // });
  //
  // enquire.register("screen and (max-width: 576px)", {
  //   match : headroom.init.bind(headroom),
  //   unmatch : headroom.destroy.bind(headroom)
  // });
  //
  // // Testimonials sliders
  // $('.slick-container').slick({
  //   infinite: true,
  //   dots: true,
  //   arrows: false,
  //   appendDots: $('.slick-nav .dots'),
  // });
  //
  // $('.slick-nav-button.prev-button').click(() => $('.slick-container').slick('slickPrev'));
  // $('.slick-nav-button.next-button').click(() => $('.slick-container').slick('slickNext'));
  //
  // // Animate on scroll settings
  // AOS.init({
  //   offset: 200,
  //   duration: 300,
  //   easing: 'ease',
  //   disable: 'mobile'
  // });

  //Burger
  $('.btn-burger').click(function() {
    $(this).toggleClass('active');
    $(document.body).toggleClass('burger-menu-visible');
    $('.mobile-hidden').toggleClass('active');
  });

  //Switch sub
  $('#switch-sub1').click(function() {
    $(this).toggleClass('active');
    $('.first-card').toggleClass('active');
  });

  //Scroll detect Input
  if ($('#cta-block-hidden').length) {
    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('resize scroll', function() {
      var footer = $('#footer');

      //Scroll Position (varies with scroll)
      var pageTop = $(document).scrollTop();
      //Visible Page Size (fixed)
      var pageSize = $(window).height();

      //Content top (fixed)
      var footerTop = footer.offset().top;
      //Content top position (varies with scroll)
      var footerTopPos = footerTop - pageTop;
      //Content bottom (fixed)
      var footerBottom =  footer.innerHeight() + footerTop;
      //Content position in relation to screen top (varies with scroll)
      var footerBottomPos = footerBottom - pageTop;

      var visibleArea = Math.min(pageSize, footerBottomPos) - Math.max(footerTopPos);

      if (footer.isInViewport()) {
        $('#cta-block-hidden').css("bottom", visibleArea + 20);
      } else {
        $('#cta-block-hidden').css("bottom", "20px");
      }

      if ($('#cta-block').isInViewport()) {
        $('#cta-block-hidden').removeClass('active');
      } else {
        $('#cta-block-hidden').addClass('active');
      }
    });

    $('.close-button').on('click', function() {
      $('#cta-block-hidden').addClass('deleted');
    })
  }

  //fadeIn
  $(window).on('resize scroll', function() {
    var fadeIn = $('.fadeIn');

    fadeIn.each(function() {
      if ($(this).isInViewport()) {
        $(this).addClass("animate");
      } else {
        $(this).removeClass("animate");
      }
    })
  });

  //Loading
  // $( window ).on( "load", function() {
  //   $('.loader').addClass('not-active');
  // });
  // $(window).on( "load",function() {
  //   $(".loader").fadeOut("1000");
  // })

  //Rotate3d scroll animation
  $( window ).scroll(function() {
    document.body.style.setProperty(
      "--scroll", window.pageYOffset / window.innerHeight
    );
  });

  // Mobile menu handle
  // $('.hamburger').click(() => {
  //   const menu = $('#menu');
  //   menu.toggleClass('open');
  //   const mobileMenu = $('#mobile-menu');
  //   mobileMenu.toggleClass('open');
  //   $('.hamburger').toggleClass('is-active');
  // });

  // Tawkto script
  // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  // (function(){
  // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  // s1.async=true;
  // s1.src='https://embed.tawk.to/5d3acf179b94cd38bbe966ff/default';
  // s1.charset='UTF-8';
  // s1.setAttribute('crossorigin','*');
  // s0.parentNode.insertBefore(s1,s0);
  // })();

  // Disable "Inscription" button in menu and open Tawkto instead
  $('.inscription-link').click(function(event) {
    event.preventDefault();
    console.log("Clicked");
    Tawk_API.toggle();
  });

  if($( ".templates-list-scrollbox" ).length) {
    // Templates click handler
    $('.template-card').click(function(event) {
      console.log($(this).data('video'));
      $('#template-video').attr('src', $(this).data('video')).trigger('play');
    });

    // Templates-list dragging
    const slider = document.querySelector('.templates-list-scrollbox');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }
});

window.addEventListener('load', AOS.refresh);

window.onload = init;

function init() {
  AOS.refresh;
  setLeftShapeTop();
  const shape = $('.background-shapes .left');
  shape.css('opacity', '1');
  // $('.loader').fadeOut();
}

function videoLoaded(){
  alert("done");
}

$('.video').on('loadstart', function (event) {
  $(this).parent().find('.loader-container').fadeIn(0);
});

$('.video').on('loadeddata', function (event) {
  $(this).parent().find('.loader-container').fadeOut(350);
});

$('.card-step-container').on("mouseenter click",function(){
  $(this).parent().find('.card-step-container').removeClass('active');
  $(this).addClass('active');
});

$(window).on('resize', function() {
  setLeftShapeTop();
});

// Sets the correct height of the SVG shape on the left
function setLeftShapeTop() {
  const shape = $('.background-shapes .left');
  const refImg = $('.pause-img');
  if (refImg.length) {
    const top = refImg.offset().top + (refImg.height()/2) - (shape.height()/2);
    shape.css({top: top});
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
